var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MSRReportWrapper"},[_c('div',{staticClass:"title-section"},[_c('div',{staticClass:"expansion-section"},[_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"msr-title"},[_vm._v(" MSR for "),_c('span',{staticClass:"title-highlight"},[_vm._v(_vm._s(_vm.monthInfo.month_name))]),_vm._v(" by "),_c('span',{staticClass:"title-highlight"},[_vm._v(_vm._s(_vm.monthInfo.mortal_data.name))])]),_c('v-expansion-panel-content',[_c('div',{staticClass:"white-space-pre-line",domProps:{"innerHTML":_vm._s(_vm.htmlTextForRendering(_vm.monthInfo.highlights))}})])],1)],1)],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.downloadReport}},[_vm._v("Export Report")])],1),_c('div',{staticClass:"highlight-section"}),(_vm.totalCount === 0)?_c('div',{staticClass:"content-error-message"},[_vm._v(" No data in the DSR for the selected product ")]):_c('div',{staticClass:"info-table"},[_c('v-data-table',{attrs:{"items-per-page":_vm.fetchCount,"hide-default-footer":"","headers":_vm.headers,"expanded":_vm.expanded,"show-expand":"","item-key":"serial_number","items":_vm.reportsList,"fixed-header":true},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.sales_call_data.date_of_call",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.sales_call_data.date_of_call, "MMMM Do YYYY dddd"))+" ")]}},{key:"item.travel_agent_employee.phone_numbers",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.travel_agent_employee.phone_numbers.join(", "))+" ")]}},{key:"item.travel_agent_employee.email_ids",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.travel_agent_employee.email_ids.join(", "))+" ")]}},{key:"item.sales_call_data.company_data",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item && item.sales_call_data.company_data ? item.sales_call_data.company_data.name : "-")+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"expandable-section table-expanded-background ",attrs:{"colspan":headers.length}},[_c('div',{staticClass:"expandable-section-title"},[_vm._v("Remark")]),_c('div',{staticClass:"expandable-section-content",domProps:{"innerHTML":_vm._s(item.meeting_remark)}},[_vm._v("{{}}")])])]}}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }